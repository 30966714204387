import React from "react";
import "../sharedLandingcss/SharedComponent.css";

const HeroComp = () => {
  return (
    <div className="relative h-screen w-full overflow-hidden">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={`${process.env.PUBLIC_URL}/images/hero/home_hero3.mp4`}
        autoPlay
        loop
        muted
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
        <h1 className="text-white text-6xl md:text-8xl font-bold leading-none fontKanitRegular">
          Soft Life
        </h1>
        <h2 className="text-white text-2xl md:text-3xl font-semibold mt-4 fontAlbertSansRegular">
          Experience A Journey to Luxurious Hair Care
        </h2>
      </div>
    </div>
  );
};

export default HeroComp;
