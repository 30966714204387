import SoftLifeAppBasicComp from "../../../components/sharedLandingcomponents/AppBasicFeatComp";
import FeatureVideo from "../../../components/sharedLandingcomponents/Fearure_Video";
import FeatureGallery from "../../../components/sharedLandingcomponents/feature_image";
import FeatureService from "../../../components/sharedLandingcomponents/Feature_Service";
import HeroComp from "../../../components/sharedLandingcomponents/Hero_Comp";
import "../landingpages.css";

export const HomePage = () => {
  return (
    <>
      <div className="grid" style={{ minHeight: "400px" }}>
        <HeroComp></HeroComp>
        {/* <TrustedBrandComp></TrustedBrandComp> */}
        <SoftLifeAppBasicComp></SoftLifeAppBasicComp>
        <FeatureVideo></FeatureVideo>
        <FeatureService></FeatureService>
        {/* <TestimonyComponent></TestimonyComponent> */}
        <FeatureGallery></FeatureGallery>
      </div>
    </>
  );
};

export default HomePage;
