import React from "react";
import { AppleOutlined, AndroidOutlined } from "@ant-design/icons";
import "../sharedLandingcss/SharedComponent.css";

const SoftLifeAppBasicComp = () => {
  return (
    <div className="bg-gray-100 p-8">
      <div className="max-w-5xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Image Row */}
          <div className="flex justify-center">
            <img
              style={{ maxHeight: "500px", width: "auto" }}
              src={`${process.env.PUBLIC_URL}/images/home/softlifeApp.png`}
              alt="Soft Life App"
              className="w-full h-auto max-w-xs md:max-w-sm rounded-lg shadow-lg"
            />
          </div>

          {/* Text Row */}
          <div>
            <h2 className="text-4xl md:text-5xl font-extrabold mb-4 fontKanitRegular leading-tight">
              ENJOY ALL SOFT LIFE APP FEATURES ON THE GO.
            </h2>
            <p className="text-lg text-black font-medium fontAlbertSansRegular">
              Discover the ultimate hair care experience with the Soft Life App.
              Book appointments, explore styles, and manage your hair wellness
              effortlessly. Your journey to perfect hair starts here.
            </p>
            <div className="flex space-x-4 mt-4">
              <a
                href="#_"
                className="bg-black text-white py-2 px-4  flex items-center space-x-2"
              >
                <AppleOutlined />
                <span>Download on the App Store</span>
              </a>
              <a
                href="#_"
                className="bg-black text-white py-2 px-4  flex items-center space-x-2"
              >
                <AndroidOutlined />
                <span>Get it on Google Play</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftLifeAppBasicComp;
