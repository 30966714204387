import PagerHeaderComp from "../../../components/sharedLandingcomponents/PagesHeaderComp";
import "../landingpages.css";

const PrivacyPolicy = () => {
  return (
    <div className="w3-white w3-col" style={{ minHeight: "400px" }}>
      {/* <PagerHeaderComp title="PRIVACY POLICY"></PagerHeaderComp> */}

      {/* Content  */}
      <div className="w3-white otherContentBg w3-col">
        {/* Motions for Traffic */}
        <div className="w3-hide-small w3-hide-medium"></div>
        <div className="w3-content w3-padding">
          <div className="fontJostRegular w3-medium">
            <>
              <p>
                <span style={{ fontWeight: 400 }}>Last updated 04-04-2024</span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  This privacy policy for LampHills ("we," "us," or "our")
                  describes how and why we might collect, store, use, and/or
                  share ("process") your information when you use our services
                  ("Services").
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Reading this privacy notice will help you understand your
                  privacy rights and choices. If you do not agree with our
                  policies and practices, please do not use our Services.&nbsp;
                </span>
              </p>
              <h2>
                <span style={{ fontWeight: 400 }}>SUMMARY OF KEY POINTS</span>
              </h2>
              <p>
                <span style={{ fontWeight: 400 }}>
                  This summary provides key points from our privacy policy.
                  Still, you can find more details about any of these topics by
                  clicking the link following each key point or using our table
                  of contents below to find the section you are looking for.
                </span>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <strong>
                    <em>What personal information do we process? </em>
                  </strong>
                  <span style={{ fontWeight: 400 }}>
                    When you visit, use, or navigate our Services, we may
                    process personal information depending on how you interact
                    with us and the Services, the choices you make, and the
                    products and features you use.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <strong>
                    <em>Do we process any sensitive personal information? </em>
                  </strong>
                  <span style={{ fontWeight: 400 }}>
                    We do not process sensitive personal information.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <strong>
                    <em>Do we receive any information from third parties? </em>
                  </strong>
                  <span style={{ fontWeight: 400 }}>
                    We may receive information from public databases, marketing
                    partners, social media platforms, and other outside
                    sources.&nbsp;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <strong>
                    <em>How do we process your information? </em>
                  </strong>
                  <span style={{ fontWeight: 400 }}>
                    We process your information to provide, improve, and
                    administer our Services, communicate with you for security
                    and fraud prevention, and comply with the law. We may also
                    process your information for other purposes with your
                    consent. We process your information only when we have a
                    valid legal reason.&nbsp;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <strong>
                    <em>
                      In what situations and with which parties do we share
                      personal information?{" "}
                    </em>
                  </strong>
                  <span style={{ fontWeight: 400 }}>
                    We may share information in specific situations and with
                    specific third parties.&nbsp;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <strong>
                    <em>What are your rights?</em>
                  </strong>
                  <span style={{ fontWeight: 400 }}>
                    {" "}
                    Depending on where you are located geographically, the
                    applicable privacy law may mean you have certain rights
                    regarding your personal information.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <strong>
                    <em>How do you exercise your rights? </em>
                  </strong>
                  <span style={{ fontWeight: 400 }}>
                    The easiest way is to submit a data subject access request
                    or contact us. We will consider and act upon any request in
                    accordance with applicable data protection laws.
                  </span>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Want to learn more about what we do with any information we
                  collect? Review the privacy policy in full.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol>
                <li>
                  <strong> WHAT INFORMATION DO WE COLLECT?</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We collect personal information that you voluntarily provide
                  us when you register on the Services, express an interest in
                  obtaining information about us or our products and Services,
                  participate in activities on the Services, or otherwise
                  contact us.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Sensitive Information</strong>
                <span style={{ fontWeight: 400 }}>
                  . We do not process sensitive information.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  All personal information you provide must be true, complete,
                  and accurate, and you must notify us of any changes to such
                  personal information.&nbsp;
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Information automatically collected</strong>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We automatically collect certain information when you visit,
                  use, or navigate the Services. This information does not
                  reveal your specific identity (like your name or contact
                  information). Still, it may include device and usage
                  information, such as your IP address, browser and device
                  characteristics, operating system, language preferences,
                  referring URLs, device name, country, location, information
                  about how and when you use our Services, and other technical
                  information. This information is primarily needed to maintain
                  the security and operation of our Services, and for our
                  internal analytics and reporting purposes.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Like many businesses, we also collect information through
                  cookies and similar technologies.
                </span>
              </p>
              <ol start={2}>
                <li>
                  <strong> HOW DO WE PROCESS YOUR INFORMATION?</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We process your personal information for various reasons,
                  depending on how you interact with our Services to provide,
                  improve, and administer our Services, communicate with you for
                  security and fraud prevention, and comply with the law. We may
                  also process your information for other purposes with your
                  consent.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={3}>
                <li>
                  <strong>
                    {" "}
                    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We may need to share your personal information in the
                  following situations:
                </span>
              </p>
              <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <strong>Business Transfers</strong>
                  <span style={{ fontWeight: 400 }}>
                    : We may share or transfer your information in connection
                    with or during negotiations of any merger, sale of company
                    assets, financing, or acquisition of all or a portion of our
                    business to another company.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <strong>Affiliates</strong>
                  <span style={{ fontWeight: 400 }}>
                    : We may share your information with our affiliates, in
                    which case we will require them to honor this privacy
                    notice. Affiliates include our parent company and any
                    subsidiaries, joint venture partners, or other companies we
                    control or are under common control.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <strong>Business Partners</strong>
                  <span style={{ fontWeight: 400 }}>
                    : We may share your information with our business partners
                    to offer certain products, services, or promotions.
                  </span>
                </li>
              </ul>
              <ol start={4}>
                <li>
                  <strong>
                    {" "}
                    DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We may use cookies and similar tracking technologies (like web
                  beacons and pixels) to access or store information. Our Cookie
                  Notice provides specific information about how we use such
                  technologies and how you can refuse certain cookies
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>.</span>
              </p>
              <ol start={5}>
                <li>
                  <strong> HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Our Services allow you to register and log in using your
                  third-party social media account details (like your Facebook
                  or Twitter logins). Wherever you choose to do this, we will
                  receive certain profile information about you from your social
                  media provider. The profile information we receive may vary
                  depending on the social media provider concerned but will
                  often include your name, email address, friends list, profile
                  picture, and other information you choose to make public on
                  such a social media platform.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We will use the information we receive only for the purposes
                  described in this privacy notice or otherwise made clear to
                  you on the relevant Services.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Please note that we do not control and are not responsible for
                  other uses of your personal information by your third-party
                  social media provider. We recommend reviewing their privacy
                  notice to understand how they collect, use, and share your
                  personal information and how you can set your privacy
                  preferences on their sites and apps.
                </span>
              </p>
              <ol start={6}>
                <li>
                  <strong>
                    {" "}
                    IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                  </strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Our servers are located in. If you access our Services from
                  outside, please be aware that your information may be
                  transferred to, stored, and processed by us in our facilities
                  and by third parties with whom we may share your personal
                  information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                  INFORMATION?" above) in other countries.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  They reside in the European Economic Area (EEA), the United
                  Kingdom (UK), and Switzerland. These countries may not
                  necessarily have data protection laws or similar laws as
                  comprehensive as those in your country. However, following
                  this privacy notice and applicable law, we will take all
                  necessary measures to protect your personal information.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={7}>
                <li>
                  <strong> HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We will only keep your personal information for as long as it
                  is necessary for the purposes set out in this privacy notice,
                  unless a longer retention period is required or permitted by
                  law (such as tax, accounting, or other legal requirements).
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  When we have no ongoing legitimate business need to process
                  your personal information, we will either delete or anonymize
                  it, or, if this is not possible (for example, because your
                  personal information has been stored in backup archives), we
                  will securely store it and isolate it from any further
                  processing until deletion is possible.
                </span>
              </p>
              <ol start={8}>
                <li>
                  <strong> DO WE COLLECT INFORMATION FROM MINORS?</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We do not knowingly solicit data from or market to children
                  under 18. By using the Services, you represent that you are at
                  least 18 or the parent or guardian of such a minor and consent
                  to such a minor dependent’s use of the Services. If we learn
                  that personal information from users less than 18 years of age
                  has been collected, we will deactivate the account and take
                  reasonable measures to delete such data from our records
                  promptly. If you become aware of any data we may have
                  collected from children under age 18, please contact us
                  through the contact email
                </span>
              </p>
              <ol start={9}>
                <li>
                  <strong> WHAT ARE YOUR PRIVACY RIGHTS?</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  You may review, change, or terminate your account at any time
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Withdrawing your consent: If we rely on your consent to
                  process your personal information, which may be express and/or
                  implied, depending on the applicable law, you can withdraw
                  your consent at any time by contacting us using the contact
                  details provided in the section "HOW CAN YOU CONTACT US ABOUT
                  THIS NOTICE?" below.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  However, please note that this will not affect the lawfulness
                  of the processing before its withdrawal, nor, when applicable
                  law allows, will it affect the processing of your personal
                  information conducted in reliance on lawful processing grounds
                  other than consent.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Account Information</strong>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Upon your request to terminate your account, we will
                  deactivate or delete it and its information from our active
                  databases. However, we may retain some information in our
                  files to prevent fraud, troubleshoot problems, assist with
                  investigations, enforce our legal terms, and/or comply with
                  applicable legal requirements.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={10}>
                <li>
                  <strong> CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Most web browsers, mobile operating systems, and mobile
                  applications include a Do-Not-Track ("DNT") feature or setting
                  you can activate to signal your privacy preference and not to
                  have data about your online browsing activities monitored and
                  collected. No uniform technology standard for recognizing and
                  implementing DNT signals has been finalized. As such, we do
                  not currently respond to DNT browser signals or any other
                  mechanism that automatically communicates your choice not to
                  be tracked online. If a standard for online tracking is
                  adopted that we must follow in the future, we will inform you
                  about that practice in a revised version of this privacy
                  notice.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={11}>
                <li>
                  <strong> DO WE MAKE UPDATES TO THIS NOTICE?</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We may update this privacy notice from time to time. The
                  updated version will be indicated by an updated "Revised" date
                  and effective as soon as it is accessible. If we make material
                  changes to this privacy notice, we may notify you by
                  prominently posting a notice or directly sending you a
                  notification. We encourage you to review this privacy notice
                  frequently to be informed of how we are protecting your
                  information.
                </span>
              </p>
              <ol start={12}>
                <li>
                  <strong> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  If you have questions or comments about this notice, you may
                  contact us through the Contact Us page&nbsp;
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={13}>
                <li>
                  <strong>
                    {" "}
                    HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                  </strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Based on the applicable laws of your country, you may have the
                  right to request access to the personal information we collect
                  from you, change that information, or delete it. To request to
                  review, update, or delete your personal information, please
                  fill out and submit a data subject access request.
                </span>
              </p>
            </>
          </div>
        </div>
      </div>
      {/* Content  */}
    </div>
  );
};

export default PrivacyPolicy;
