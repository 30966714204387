import React, { useState } from "react";
import { Collapse } from "antd";
import "../sharedLandingcss/SharedComponent.css";

const { Panel } = Collapse;

const FaqComp = () => {
  const faqs = [
    {
      question: "What is the Soft Life App?",
      answer:
        "The Soft Life App is your ultimate hair care companion. It allows you to book appointments, explore styles, and manage your hair wellness effortlessly.",
    },
    {
      question: "How do I book an appointment?",
      answer:
        "Booking an appointment is easy. Simply open the app, navigate to the booking section, choose your preferred service, and select a time slot that suits you.",
    },
    {
      question: "Does the app support all hair types?",
      answer:
        "Yes, the Soft Life App is designed to cater to all hair types, ensuring that everyone gets the care they need.",
    },
    {
      question: "Are the products recommended in the app safe to use?",
      answer:
        "Absolutely! All products recommended in the app are carefully selected to ensure they are safe and effective for your hair care needs.",
    },
    {
      question: "Can I get personalized hair care tips?",
      answer:
        "Yes, the app provides personalized hair care tips based on your hair type and preferences, helping you achieve healthier hair.",
    },
    {
      question: "Is there a subscription fee for using the app?",
      answer:
        "The app is free to download and use. However, certain premium features may require a subscription.",
    },
    {
      question: "How can I contact customer support?",
      answer:
        "You can contact our customer support team through the app's contact section or by emailing us at support@softlifeapp.com.",
    },
  ];

  return (
    <div className="bg-gray-100 py-16">
      <div className="max-w-5xl mx-auto px-4">
        <h2 className="text-4xl font-extrabold text-center mb-8 fontKanitRegular">
          Frequently Asked Questions
        </h2>
        <Collapse accordion>
          {faqs.map((faq, index) => (
            <Panel header={faq.question} key={index}>
              <p className="text-black fontAlbertSansRegular">{faq.answer}</p>
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default FaqComp;
