import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleLinkClick = () => {
    setShowMenu(false);
  };

  return (
    <nav className="bg-white shadow-lg fixed w-full z-10 pt-4 pb-4">
      <div className="container mx-auto px-6 py-3 flex justify-between items-center">
        <div className="flex items-center">
          <img
            src={`${process.env.PUBLIC_URL + "/images/logo.png"}`}
            alt="Logo"
            className="h-8 w-32 mr-3"
          />
          <div className="hidden md:flex space-x-6 ml-6">
            <Link
              to="/home"
              className="text-black hover:text-gray-600 fontKanitRegular font-medium"
            >
              HOME
            </Link>
            <Link
              to="/about"
              className="text-black hover:text-gray-600 fontKanitRegular font-medium"
            >
              ABOUT
            </Link>
            <Link
              to="/#_"
              className="text-black hover:text-gray-600 fontKanitRegular font-medium"
            >
              BLOG
            </Link>
            <Link
              to="/contact"
              className="text-black hover:text-gray-600 fontKanitRegular font-medium"
            >
              CONTACT
            </Link>
          </div>
        </div>
        <div className="hidden md:flex">
          <a
            href="#_"
            className="bg-black text-white px-4 py-4 hover:bg-gray-800 fontKanitRegular font-medium"
          >
            Download App
          </a>
        </div>
        <div className="md:hidden flex items-center">
          <button
            className="outline-none mobile-menu-button"
            onClick={() => setShowMenu(!showMenu)}
          >
            <svg
              className="w-6 h-6 text-gray-500"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d={showMenu ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              />
            </svg>
          </button>
        </div>
      </div>
      {showMenu && (
        <div className="mobile-menu">
          <ul className="">
            <li>
              <Link
                to="/home"
                className="block text-sm px-2 py-4 text-black hover:text-gray-600 fontKanitRegular font-medium"
                onClick={handleLinkClick}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="block text-sm px-2 py-4 text-black hover:text-gray-600 fontKanitRegular font-medium"
                onClick={handleLinkClick}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/#_"
                className="block text-sm px-2 py-4 text-black hover:text-gray-600 fontKanitRegular font-medium"
                onClick={handleLinkClick}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="block text-sm px-2 py-4 text-black hover:text-gray-600 fontKanitRegular font-medium"
                onClick={handleLinkClick}
              >
                Contact
              </Link>
            </li>
            <li>
              <a
                href="#_"
                className="block text-sm px-2 py-4 text-black hover:text-gray-600 fontKanitRegular font-medium"
                onClick={handleLinkClick}
              >
                Download
              </a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
