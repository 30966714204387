import AppBasicAbout from "../../../components/sharedLandingcomponents/AppBasicAboutComp";
import FeatureGallery from "../../../components/sharedLandingcomponents/feature_image";
import FeatureServiceExtra from "../../../components/sharedLandingcomponents/Feature_Service_Extra";
import PagerHeaderComp from "../../../components/sharedLandingcomponents/PagesHeaderComp";
import "../landingpages.css";

export const AboutPage = () => {
  return (
    <>
      <div className="grid" style={{ minHeight: "400px" }}>
        <PagerHeaderComp
          image={`${process.env.PUBLIC_URL}/images/hero/home-3-rev-img-01.jpg`}
          title="About Us"
          subtitle="Experience A Journey to Luxurious Hair Care"
        />

        <AppBasicAbout></AppBasicAbout>
        <FeatureServiceExtra></FeatureServiceExtra>
        <FeatureGallery></FeatureGallery>
      </div>
    </>
  );
};

export default AboutPage;
