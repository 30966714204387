import React from "react";
import "./Footer.css";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-4 px-8">
      <div className="container mx-auto text-center">
        <img
          src={`${process.env.PUBLIC_URL + "/images/logo.png"}`}
          alt="Logo"
          className="mx-auto h-12 mb-4"
        />

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          <div className="md:text-left">
            <h2 className="text-2xl font-semibold mb-4 fontKanitRegular">
              Services
            </h2>
            <ul>
              <li className="mb-2 fontAlbertSansRegular font-medium">
                Haircut
              </li>
              <li className="mb-2 fontAlbertSansRegular font-medium">
                Hair Coloring
              </li>
              <li className="mb-2 fontAlbertSansRegular font-medium">
                Styling
              </li>
              <li className="mb-2 fontAlbertSansRegular font-medium">Perm</li>
              <li className="mb-2 fontAlbertSansRegular font-medium">
                Hair Extensions
              </li>
              <li className="mb-2 fontAlbertSansRegular font-medium">
                Scalp Treatment
              </li>
            </ul>
          </div>

          <div>
            <p className="text-4xl font-semibold mb-16 mt-16 fontKanitRegular">
              IT'S NOT JUST A HAIR. <br /> IT'S STATE OF MIND.
            </p>
            <div className="flex justify-center space-x-4">
              <a href="#" className="text-gray-400 hover:text-white">
                <FacebookOutlined className="text-xl" />
              </a>
              <a href="#" className="text-gray-400 hover:text-white">
                <InstagramOutlined className="text-xl" />
              </a>
              <a href="#" className="text-gray-400 hover:text-white">
                <TwitterOutlined className="text-xl" />
              </a>
            </div>
          </div>

          <div className="md:text-right">
            <h2 className="text-2xl font-semibold mb-4 fontKanitRegular">
              Contact Us
            </h2>
            <ul>
              <li className="mb-2 fontAlbertSansRegular font-medium">
                Email: info@softlifeng.com
              </li>
              <li className="mb-2 fontAlbertSansRegular font-medium">
                Phone: +2349075657273
              </li>
              <li className="mb-2 fontAlbertSansRegular font-medium">
                Address: H15A road 35, Victoria Garden city
              </li>
              <li className="mb-2 fontAlbertSansRegular font-medium">
                Mon - Fri: 9am - 6pm
              </li>
              <li className="mb-2 fontAlbertSansRegular font-medium">
                Sat: 10am - 4pm
              </li>
              <li className="mb-2 fontAlbertSansRegular font-medium">
                Sun: Closed
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
