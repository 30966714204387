import React from "react";
import {
  CheckCircleOutlined,
  StarOutlined,
  AppstoreAddOutlined,
  SafetyCertificateOutlined,
  UsergroupAddOutlined,
  WalletOutlined,
  BranchesOutlined,
  GiftOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import "../sharedLandingcss/SharedComponent.css";

const FeatureServiceExtra = () => {
  const features = [
    {
      icon: <CheckCircleOutlined className="text-3xl text-blue-500" />,
      header: "RIGHT HAIR CARE",
      text: "Get the best advice for taking care of your hair. Personalized tips and routines for healthier hair.",
    },
    {
      icon: <StarOutlined className="text-3xl text-yellow-500" />,
      header: "ALL HAIR TYPES",
      text: "Our services cater to all hair types, ensuring that everyone gets the care they need.",
    },
    {
      icon: <AppstoreAddOutlined className="text-3xl text-green-500" />,
      header: "SELECTIVE PRODUCTS",
      text: "Choose from a range of carefully selected products to enhance your hair care regimen.",
    },
    {
      icon: <SafetyCertificateOutlined className="text-3xl text-red-500" />,
      header: "CERTIFIED EXPERTS",
      text: "Our team consists of certified experts who provide top-notch advice and services.",
    },
    {
      icon: <UsergroupAddOutlined className="text-3xl text-purple-500" />,
      header: "COMMUNITY SUPPORT",
      text: "Join a community of hair care enthusiasts and share your experiences.",
    },
    {
      icon: <WalletOutlined className="text-3xl text-teal-500" />,
      header: "AFFORDABLE PLANS",
      text: "Enjoy high-quality services and products at affordable prices.",
    },
    {
      icon: <BranchesOutlined className="text-3xl text-orange-500" />,
      header: "EXPANSIVE RANGE",
      text: "Explore an extensive range of hair care products and services tailored to your needs.",
    },
    {
      icon: <GiftOutlined className="text-3xl text-pink-500" />,
      header: "EXCLUSIVE OFFERS",
      text: "Take advantage of exclusive offers and discounts available through the app.",
    },
    {
      icon: <MobileOutlined className="text-3xl text-blue-600" />,
      header: "MOBILE FRIENDLY",
      text: "Access all features seamlessly through our user-friendly mobile app.",
    },
  ];

  return (
    <div className="py-32 px-4 bg-white">
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center p-4 bg-white border rounded-lg shadow-lg"
          >
            <div className="mb-4">{feature.icon}</div>
            <h3 className="text-xl font-bold mb-2 fontKanitRegular">
              {feature.header}
            </h3>
            <p className="text-gray-900 font-medium fontAlbertSansRegular">
              {feature.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureServiceExtra;
