import React from "react";
import { AppleOutlined, AndroidOutlined } from "@ant-design/icons";
import "../sharedLandingcss/SharedComponent.css";

const AppBasicAbout = () => {
  return (
    <div className="bg-green-200 px-8 py-24">
      <div className="max-w-5xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Image Row */}
          <div className="flex justify-center">
            <img
              style={{ width: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/aboutPage/aboutpics3.jpg`}
              alt="About"
              className="w-full h-auto max-w-xs md:max-w-sm rounded-lg shadow-lg"
            />
          </div>

          {/* Text Row */}
          <div>
            <h2 className="text-4xl md:text-5xl font-extrabold mb-8 fontKanitRegular leading-tight">
              LIFE IS MORE BEAUTIFUL WHEN YOU MEET THE RIGHT HAIRDRESSER.
            </h2>
            <p className="text-lg text-black font-medium fontAlbertSansRegular">
              Experience the best in hair care with our app. Access expert
              advice, schedule your appointments, and stay on top of your hair
              care routine, all from the palm of your hand.
            </p>
            <div className="flex space-x-4 mt-8">
              <a
                href="#_"
                className="bg-black text-white py-2 px-4 flex items-center space-x-2 rounded"
              >
                <AppleOutlined />
                <span>Download on the App Store</span>
              </a>
              <a
                href="#_"
                className="bg-black text-white py-2 px-4 flex items-center space-x-2 rounded"
              >
                <AndroidOutlined />
                <span>Get it on Google Play</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppBasicAbout;
