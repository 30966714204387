import React from "react";
import "../sharedLandingcss/SharedComponent.css";

const FeatureService = () => {
  const features = [
    {
      icon: `${process.env.PUBLIC_URL}/images/services/home-3-icon-03.png`,
      header: "RIGHT HAIR CARE",
      text: "Get the best advice for taking care of your hair. Personalized tips and routines for healthier hair.",
    },
    {
      icon: `${process.env.PUBLIC_URL}/images/services/Home-3-icon.png`,
      header: "ALL HAIR TYPES",
      text: "Our services cater to all hair types, ensuring that everyone gets the care they need.",
    },
    {
      icon: `${process.env.PUBLIC_URL}/images/services/home-3-icon-02.png`,
      header: "SELECTIVE PRODUCTS",
      text: "Choose from a range of carefully selected products to enhance your hair care regimen.",
    },
  ];

  return (
    <div className="py-32 px-4 bg-white">
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center p-4 bg-white"
          >
            <div className="mb-4">
              <img
                src={feature.icon}
                alt={`${feature.header} Icon`}
                className="w-auto h-20"
              />
            </div>
            <h3 className="text-xl font-bold mb-2 fontKanitRegular">
              {feature.header}
            </h3>
            <p className="text-gray-900 font-medium fontAlbertSansRegular">
              {feature.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureService;
