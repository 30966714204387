import ContactComp from "../../../components/sharedLandingcomponents/Contact_Comp";
import FaqComp from "../../../components/sharedLandingcomponents/Faq_Comp";
import FeatureGallery from "../../../components/sharedLandingcomponents/feature_image";
import PagerHeaderComp from "../../../components/sharedLandingcomponents/PagesHeaderComp";
import "../landingpages.css";

export const ContactPage = () => {
  return (
    <>
      <div className="grid" style={{ minHeight: "400px" }}>
        <PagerHeaderComp
          image={`${process.env.PUBLIC_URL}/images/hero/home-3-rev-img-02.jpg`}
          title="Contact Us"
          subtitle="Experience A Journey to Luxurious Hair Care"
        />
        <FaqComp></FaqComp>
        <ContactComp></ContactComp>
        <FeatureGallery></FeatureGallery>
      </div>
    </>
  );
};

export default ContactPage;
