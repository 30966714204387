import React, { useState } from "react";
import { Modal } from "antd";
import "../sharedLandingcss/SharedComponent.css";

const FeatureGallery = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const showModal = (image) => {
    setModalImage(image);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const images = [
    `${process.env.PUBLIC_URL}/images/gram/gram01.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram02.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram03.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram04.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram05.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram06.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram07.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram08.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram09.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram10.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram11.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram12.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram13.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram14.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram15.jpg`,
    `${process.env.PUBLIC_URL}/images/gram/gram16.jpg`,
  ];

  return (
    <div>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-0">
        {images.map((image, index) => (
          <div key={index} className="relative">
            <img
              src={image}
              alt={`Gallery Image_ ${index + 1}`}
              className="w-full h-full object-cover cursor-pointer"
              onClick={() => showModal(image)}
            />
          </div>
        ))}
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
        width="80%"
        bodyStyle={{
          background: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalImage}
          alt="Modal"
          className="h-auto max-h-[80vh] max-w-full"
          style={{ maxHeight: "80vh" }}
        />
      </Modal>
    </div>
  );
};

export default FeatureGallery;
